var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_c('h1',{staticClass:"p-1"},[_vm._v(_vm._s(_vm.$t("Box from "))+_vm._s(_vm.user)+"!")])]),_c('b-col',[_c('b-row',[_c('b-col',[(_vm.CashStats)?_c('p',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold my-1"},[_vm._v("Opening: "+_vm._s(_vm.CashStats.opening))])]):_vm._e()])],1)],1)],1),_c('br'),_c('hr',{staticClass:"style-seven"}),(_vm.CashStats)?_c('div',[_c('CardCash',{attrs:{"CashStats":_vm.CashStats}})],1):_c('div',[_c('CardCash',{attrs:{"CashStats":{
        income: 0,
        current_balance: 0,
        current_descriptive: 0,
      }}})],1),_c('b-table',{staticClass:"mt-2",attrs:{"small":"","hover":"","fields":_vm.fields,"items":_vm.items,"responsive":"sm","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(row){return [_c('b-form-checkbox',{staticClass:"vs-checkbox-con",on:{"change":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}})]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('CardCash',{attrs:{"CashStats":{
            income: row.item.income,
            current_balance: row.item.current_balance,
            current_descriptive: row.item.current_descriptive,
          }}}),_c('b-table',{staticClass:"mt-2",attrs:{"small":"","bordered":"","responsive":"sm","show-empty":"","empty-text":"No matching records found","fields":_vm.fieldsCash,"items":row.item.movements},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('b-link',{attrs:{"to":{
                name: 'recipe-patient',
                params: { id: data.item.movementable_id },
              }}},[_vm._v(_vm._s(data.item.event.name))])]}},{key:"cell(value)",fn:function(data){return [_c('b-badge',{attrs:{"pill":"","variant":_vm.getColor(data.item.event.movement_types_id)}},[_c('i',{staticClass:"mr-2 text-white",class:_vm.getIcon(data.item.event.movement_types_id)}),_vm._v(" "+_vm._s("$ " + data.value)+" ")])]}}],null,true)})],1)]}},{key:"cell(frontdesks)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.frontdesks.avatar}})]},proxy:true}],null,true)},[_c('b-link',{attrs:{"to":{
            name: 'admin-users-view',
            params: { id: data.item.frontdesks.id },
          }}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap d-flex flex-column align-items-start"},[_c('span',[_vm._v(" "+_vm._s(data.item.frontdesks.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(data.item.frontdesks.lastname)+" ")])])])],1)]}},{key:"cell(income)",fn:function(data){return [_c('b-badge',{attrs:{"pill":"","variant":"success"}},[_vm._v(" "+_vm._s("$ " + data.value)+" ")])]}}])}),(_vm.$can('index', 'cash_closing'))?_c('router-link',{staticClass:"text-white",attrs:{"to":"cash-closing"}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(" Close Cash ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }