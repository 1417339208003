<template>
  <b-card>
    <b-row>
      <b-col cols="9">
        <h1 class="p-1">{{ $t("Box from ") }}{{ user }}!</h1>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <p v-if="CashStats" class="d-flex flex-column">
              <span class="font-weight-bold my-1"
                >Opening: {{ CashStats.opening }}</span
              >
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br />
    <hr class="style-seven" />
    <div v-if="CashStats">
      <CardCash :CashStats="CashStats" />
    </div>
    <div v-else>
      <CardCash
        :CashStats="{
          income: 0,
          current_balance: 0,
          current_descriptive: 0,
        }"
      />
    </div>
    <b-table
      small
      hover
      :fields="fields"
      :items="items"
      responsive="sm"
      class="mt-2"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
        </b-form-checkbox>
      </template>
      <template #row-details="row">
        <b-card>
          <CardCash
            :CashStats="{
              income: row.item.income,
              current_balance: row.item.current_balance,
              current_descriptive: row.item.current_descriptive,
            }"
          />
          <b-table
            small
            bordered
            responsive="sm"
            class="mt-2"
            show-empty
            empty-text="No matching records found"
            :fields="fieldsCash"
            :items="row.item.movements"
          >
            <template #cell(name)="data">
              <b-link
                :to="{
                  name: 'recipe-patient',
                  params: { id: data.item.movementable_id },
                }"
                >{{ data.item.event.name }}</b-link
              >
            </template>
            <template #cell(value)="data">
              <b-badge
                pill
                :variant="getColor(data.item.event.movement_types_id)"
              >
                <i
                  :class="getIcon(data.item.event.movement_types_id)"
                  class="mr-2 text-white"
                ></i>
                {{ "$ " + data.value }}
              </b-badge>
            </template>
          </b-table>
        </b-card>
      </template>

      <template #cell(frontdesks)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar size="32" :src="data.item.frontdesks.avatar" />
          </template>
          <b-link
            :to="{
              name: 'admin-users-view',
              params: { id: data.item.frontdesks.id },
            }"
          >
            <span
              class="font-weight-bold d-block text-nowrap d-flex flex-column align-items-start"
            >
              <span>
                {{ data.item.frontdesks.name }}
              </span>
              <span>
                {{ data.item.frontdesks.lastname }}
              </span>
            </span>
          </b-link>
        </b-media>
      </template>
      <!-- A virtual composite column -->
      <template #cell(income)="data">
        <b-badge pill variant="success">
          {{ "$ " + data.value }}
        </b-badge>
      </template>
    </b-table>
    <router-link to="cash-closing" class="text-white" v-if="$can('index', 'cash_closing')">
      <b-button variant="primary">
        Close Cash
      </b-button>
    </router-link>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormCheckbox,
  BBadge,
  BMedia,
  BAvatar,
  BButton,
  BLink,
} from "bootstrap-vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import axiosC from "@/core/services/api/cash-account";

import CardCash from "./CardCash";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BBadge,
    BMedia,
    BAvatar,
    BLink,
    BButton,
    CardCash,
  },
  mounted() {
    this.getCash();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "cash-account", route: "" },
      { title: "Cash Account" },
    ]);
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("userData")).name,
      fields: [
        // A column that needs custom formatting
        { key: "frontdesks", label: "Name" },
        // A virtual column made up from two fields
        { key: "opening", sortable: true },
        { key: "income", label: "Amount", sortable: true },
        { key: "show_details" },
      ],
      fieldsCash: [
        { key: "name", label: "Name" },
        { key: "event.movement_types.name", label: "Movement" },
        { key: "value", label: "Amount", sortable: true },
      ],
      items: [],
      CashStats: null,
      visitStats: null,
    };
  },
  methods: {
    getCash() {
      axiosC.cashGlobalList().then(({ registro }) => {
        this.visitStats = registro.visits;
        this.CashStats = registro.cash;
        this.items = registro.cash.cashes;
      });
    },
    getColor(id) {
      if (id === 1) return "success";
      if (id === 2) return "danger";
      if (id === 3) return "success";
      if (id === 4) return "danger";
      if (id === 5) return "secondary";
      return "success";
    },
    getIcon(id) {
      if (id === 1 || id === 3) return "fas fa-arrow-up";
      if (id === 2 || id === 4) return "fas fa-arrow-down";
      return "fas fa-arrow-down";
    },
  },
};
</script>

<style lang="scss" scope>
hr.style-seven {
  overflow: visible; /* For IE */
  height: 30px;
  border-style: solid;
  border-color: black;
  border-width: 1px 0 0 0;
  border-radius: 20px;
}
hr.style-seven:before {
  /* Not really supposed to work, but does */
  display: block;
  content: "";
  height: 30px;
  margin-top: -31px;
  border-style: solid;
  border-color: black;
  border-width: 0 0 1px 0;
  border-radius: 20px;
}
</style>
